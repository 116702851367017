<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
        <span
          v-for="(tab, index) in tabs"
          :key="index"
          class="mr-5"
          :class="{'text-primary': tab.value === selectedTab}"
          style="cursor: pointer"
          @click="selectTab(tab.value)"
        >
          {{ tab.name }}
        </span>
      </h5>
    </div>
  </template>
  <template #toolbar>
    <div class="example-tools justify-content-center">
      <router-link
        v-if="selectedTab === tabs[1].value"
        :to="{ name: 'adminTemplatesCreate', params: { id: $route.params.id } }"
        class="btn btn-light-success font-weight-bold btn-sm mr-5"
      >
        <span class="svg-icon btn-light-success">
          <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
        </span>
        {{ $t('TEMPLATES.NEW') }}
      </router-link>
      <router-link :to="{ name: 'company' }" class="btn btn-secondary font-weight-bold btn-sm">
        {{ $t('BASE.BACK') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <!--      <EditForm/>-->
    <component :is="selectedTab" />
  </template>
</KTCard>
</template>

<script>
import KTCard from '../../../../content/Card'

const EditForm = () => import('@/components/admin/company/EditForm')
const TemplatesList = () => import('@/components/admin/templates/List')
export default {
  name: 'CompanyEdit',
  components: {
    KTCard,
    EditForm,
    TemplatesList, 
  },
  data() {
    return {
      selectedTab: 'EditForm',
      tabs: [
        {
          value: 'EditForm',
          name: this.$t('COMPANY.TITLE_EDIT'),
        },
        {
          value: 'TemplatesList',
          name: this.$t('COMPANY.RECEIPT_CONF'),
        },
      ],
    }
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = tab
    },
  },
}
</script>

<style scoped>

</style>
